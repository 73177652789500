const _isEnterKey = event => event.keyCode === 13
const _isEscapeKey = event => event.keyCode === 27

export const pressedEnter = fn => event => {
  if (_isEnterKey(event)) {
    fn(event)
  }
}

export const pressedEscape = fn => event => {
  if (_isEscapeKey(event)) {
    fn(event)
  }
}
