import React from 'react'
import { BEURO__Icon, Spinner } from 'styleguide/packages/components/index.js'
import { classes } from 'styleguide/packages/util/index.js'
import PropTypes from 'prop-types'

const BEURO__Btn = props => {
  const {
    name = '',
    type = 'submit',
    className = '',
    onClick,
    content = '',
    icon = null,
    iconWidth,
    iconHeight,
    viewBox,
    isFixed = false,
    isSmall = false,
    isPending = false,
    isDisabled = false,
    ...rest
  } = props

  const classNames = classes([
    className,
    'BEURO__btn',
    {
      '--disabled': isDisabled,
      '--fixed': isFixed,
      '--small': isSmall,
      '--pending': isPending,
    },
  ])

  return (
    <button
      name={name}
      type={type}
      className={classNames}
      disabled={isDisabled}
      onClick={onClick}
      aria-label={name.replace(/-/g, ' ')}
      {...rest}>
      <div className={`${icon || isPending ? 'content-wrapper' : ''} ${!content && '--no-text'}`}>
        {content && <p className={isPending ? '--hide' : ''}>{content}</p>}
        {icon && (
          <BEURO__Icon
            className={`icon-container ${isPending ? '--hide' : ''}`}
            width={iconWidth}
            height={iconHeight}
            viewBox={viewBox}
            name={icon}
          />
        )}
        {isPending && <Spinner />}
      </div>
    </button>
  )
}

// BEURO__Btn serves as the base component for our button types
BEURO__Btn.Green = props => <BEURO__Btn {...props} className={`${props.className} --green`} />
BEURO__Btn.Destructive = props => (
  <BEURO__Btn {...props} className={`${props.className} --destructive`} />
)
BEURO__Btn.Gold = props => <BEURO__Btn {...props} className={`${props.className} --gold`} />
BEURO__Btn.Ghost = props => <BEURO__Btn {...props} className={`${props.className} --ghost`} />
BEURO__Btn.GhostDark = props => (
  <BEURO__Btn {...props} className={`${props.className} --ghost --dark`} />
)
BEURO__Btn.Link = props => <BEURO__Btn {...props} className={`${props.className} --link`} />
BEURO__Btn.Transparent = props => (
  <BEURO__Btn {...props} className={`${props.className} --transparent`} />
)

BEURO__Btn.propTypes = {
  name: PropTypes.string,
  isFixed: PropTypes.bool,
  isSmall: PropTypes.bool,
  isPending: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default BEURO__Btn
