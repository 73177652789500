const classes = obj => {
  let out = ''

  if (typeof obj === 'string' || typeof obj === 'number') return obj || ''

  if (Array.isArray(obj))
    for (let k = 0, tmp; k < obj.length; k++) {
      if ((tmp = classes(obj[k])) !== '') {
        out += (out && ' ') + tmp
      }
    }
  else
    for (let k in obj) {
      if (obj.hasOwnProperty(k) && obj[k]) out += (out && ' ') + k
    }

  return out
}

export default classes
