import { pressedEscape } from 'styleguide/packages/util/index.js'

export const toggleOpen = id => {
  const escFunction = pressedEscape(() => toggleClose(id))

  document.addEventListener('keydown', escFunction, false)
  document.getElementById(id).classList.add('--open')
  document.body.style.overflow = 'hidden'
}

export const toggleClose = (id, repeat = true) => {
  if (repeat) {
    const escFunction = pressedEscape(() => toggleClose(id, false))

    document.removeEventListener('keydown', escFunction, false)
    document.getElementById(id).classList.remove('--open')
    document.body.style.overflow = 'visible'
  }
}
